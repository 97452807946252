export const learningLevels = [
  {
    id: 1,
    frontTitle: 'A1',
    frontDesc: 'Қарапайым деңгей',
    backTitle: 'A1 Деңгейі',
    path: 'auth/login.php',
  },
  {
    id: 1,
    frontTitle: 'A2',
    frontDesc: 'Базалық деңгей',
    backTitle: 'A2 Деңгейі',
    path: 'auth/login.php',
  },
  {
    id: 1,
    frontTitle: 'B1',
    frontDesc: 'Орта деңгей',
    backTitle: 'B1 Деңгейі',
    path: 'auth/login.php',
  },
  {
    id: 1,
    frontTitle: 'B2',
    frontDesc: 'Ортадан жоғары деңгей',
    backTitle: 'B2 Деңгейі',
    path: 'auth/login.php',
  },
  {
    id: 1,
    frontTitle: 'C1',
    frontDesc: 'Жоғары деңгей',
    backTitle: 'C1 Деңгейі',
    path: 'auth/login.php',
  },
];

export const bookImages = ['A1', 'A2', 'B1', 'B2', 'C1'];
