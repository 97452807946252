import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/header/header';
import { Footer } from '../../components/footer/footer';
import styles from './mainPage.module.scss';
import { LevelCard } from './components/levelCard/levelCard';
import { bookImages, learningLevels } from './mock';
import { useNavigate } from 'react-router-dom';

export const MainPage = () => {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const sliderInterval = useRef<NodeJS.Timer>();

  const nextSlide = () => {
    setCurrentSlide((prevState) => {
      if (prevState === 4) return 0;
      return prevState + 1;
    });
  };
  const prevSlide = () => {
    setCurrentSlide((prevState) => {
      if (prevState === 0) return 4;
      return prevState - 1;
    });
  };

  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide();
    }, 4000);
    sliderInterval.current = timer;
    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      {/*<Header />*/}
      <div className={styles.banner}>
        <div className="container">
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-3">
            <img
              src="/main/images/ornament-1.png"
              alt="ornament"
              width={91}
              height={91}
              className="tw-animate-fadeInUp"
            />
            <h1 className="tw-text-[#0C276C] tw-text-[53px] tw-font-[600] text-center tw-animate-fadeInUp">
              Бізбен бірге қазақ тілі әлеміне еніңіз!
            </h1>
            <p className="tw-text-[#54595F] text-center tw-animate-fadeInUp">
              Тілді меңгеру деңгейіңізді бағалау үшін тестілеуден өтіңіз. Егер тілді білсеңіз, қажетті деңгейге өтіңіз.
            </p>
            <a href="auth/login.php">
              <button
                className={`${styles.buttonOutline} tw-animate-fadeInUp`}
                // onClick={() => navigate('learn/level-quiz')}
              >
                Тестке өтіңіз
              </button>
            </a>
          </div>
          <div className="tw-flex tw-flex-col tw-items-center tw-gap-[20px] lg:tw-flex-row tw-mt-[100px]">
            {learningLevels.map((level, index) => (
              <LevelCard
                frontTitle={level.frontTitle}
                frontDesc={level.frontDesc}
                backTitle={level.backTitle}
                path={level.path}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={`${styles.bookBlockWrap} `}>
        <div className="container tw-mt-[50px] tw-flex tw-flex-col-reverse tw-items-center lg:tw-items-start lg:tw-flex-row tw-gap-[70px] tw-animate-fadeInUp">
          <div className="tw-relative tw-w-[300px] sm:tw-w-[432px] tw-mb-10">
            {bookImages.map((book, index) => (
              <img
                src={`/main/images/${book}.png`}
                alt={book}
                width={432}
                className={`tw-transition-all tw-duration-500 tw-w-[300px] sm:tw-w-[432px] tw-animate-fadeIn ${index !== currentSlide ? 'tw-hidden' : ''}`}
              />
            ))}
            <div
              className="tw-absolute tw-top-[48%] tw-left-[2.5%] tw-cursor-pointer"
              onClick={() => {
                if (sliderInterval?.current) {
                  clearInterval(sliderInterval?.current);
                }
                prevSlide();
              }}
            >
              <div className="tw-text-5xl tw-text-[#eeeeee] tw-scale-x-50">{'<'}</div>
            </div>
            <div
              className="tw-absolute tw-top-[48%] tw-right-[2.5%] tw-cursor-pointer"
              onClick={() => {
                if (sliderInterval?.current) {
                  clearInterval(sliderInterval?.current);
                }
                nextSlide();
              }}
            >
              <div className="tw-text-5xl tw-text-[#eeeeee] tw-scale-x-50">{'>'}</div>
            </div>
            <div className="tw-mt-5 tw-flex tw-gap-3 tw-justify-center">
              {bookImages.map((img, index) => (
                <span
                  className={`tw-block tw-rounded-[50%] tw-w-[6px] tw-h-[6px] tw-bg-black tw-cursor-pointer ${index !== currentSlide ? 'tw-opacity-20' : ''}`}
                  onClick={() => setCurrentSlide(index)}
                ></span>
              ))}
            </div>
          </div>
          <div className="tw-animate-fadeInUp">
            <div className="tw-flex tw-gap-7 tw-items-center tw-flex-col sm:tw-flex-row">
              <h2 className="tw-text-[#5F90C6] tw-text-[62px] tw-font-[600] tw-mb-0">A1</h2>
              <h2 className="tw-text-[#5F90C6] tw-font-[600]">Учебник для поставщиков услуг в нотариальной сфере!</h2>
            </div>
            <p className="tw-text-[#54595F] tw-mt-2">
              Откройте для себя основы казахского языка с нашим новым учебником, разработанным Центром преподавания
              языков Карагандинской области! Этот учебник, предназначенный специально для поставщиков услуг в
              нотариальной сфере, включает в себя 24 динамичных урока, ориентированных на уровень A1. Он идеально
              подходит для тех, кто стремится быстро овладеть навыками общения на казахском языке, необходимыми в
              профессиональной деятельности.
            </p>
            <p className="tw-text-[#54595F]">
              Созданный с учетом потребностей специалистов нотариальной сферы, этот учебник не только представляет
              материал в логично структурированном порядке, но и обогащен яркими иллюстрациями для лучшего усвоения. Он
              обеспечивает четкое понимание языковых структур и лексики, что делает процесс обучения интуитивно понятным
              и эффективным.
            </p>
            <p className="tw-text-[#54595F] tw-font-[600]">
              Начните свой путь к владению казахским языком с уверенностью и профессионализмом с нашим
              специализированным учебником!
            </p>
            <div className="tw-mt-[50px] tw-flex tw-flex-col sm:tw-flex-row tw-justify-between tw-items-center tw-mx-5">
              <div>
                <h5 className="tw-text-[#0078CA] tw-text-[18px] tw-font-[600] tw-mb-0">Авторы</h5>
                <p className="tw-text-[#7A7A7A] tw-font-[600] tw-mb-0">Игенова А. А.</p>
                <p className="tw-text-[#7A7A7A] tw-font-[600]">Агымбаева М.Ш.</p>
              </div>
              <a
                href="https://saryarqa-3.kz/"
                target="_blank"
                className={`${styles.buttonOutline} tw-h-[56px] tw-no-underline`}
              >
                Начать изучение
              </a>
            </div>
          </div>
        </div>
      </div>
      {/*<Footer />*/}
    </div>
  );
};
