import React from 'react';
import './App.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Outlet, Route, Routes } from 'react-router-dom';
import CategoriesPage from './pages/categoriesPage/categoriesPage';
import ChaptersPage from './pages/chaptersPage/chaptersPage';
import ChaptersDetailsPage from './pages/chapterDetailsPage/chapterDetailsPage';
import { QuizPage } from './pages/quizPage/quizPage';
import { LearningPagesLayout } from './layout/learningPagesLayout';
import { MainPage } from './pages/mainPage/mainPage';
import LevelQuizPage from './pages/levelQuizPage/levelQuizPage';

function App() {
  return (
    <Routes>
      <Route path={'/'} element={<MainPage />} />
      {/*<Route path={'/learn'} element={<LearningPagesLayout />}>*/}
      {/*  <Route path={'a1'} element={<Outlet />}>*/}
      {/*    <Route element={<CategoriesPage />} path={'categories'} />*/}
      {/*    <Route element={<ChaptersPage />} path={'categories/:categoryId/chapters'} />*/}
      {/*    <Route element={<ChaptersDetailsPage />} path={'categories/:categoryId/chapters/:chapterId'} />*/}
      {/*    <Route element={<QuizPage />} path={'categories/:categoryId/chapters/:chapterId/quiz'} />*/}
      {/*  </Route>*/}
      {/*  <Route path="level-quiz" element={<LevelQuizPage />} />*/}
      {/*</Route>*/}
    </Routes>
  );
}

export default App;
