import React, { FC } from 'react';
import styles from './levelCard.module.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
  frontTitle: string;
  frontDesc: string;
  backTitle: string;
  path: string;
  index: number;
}

export const LevelCard: FC<Props> = (props) => {
  const navigate = useNavigate();
  return (
    <div
      className={`${styles.flipCard} tw-animate-fadeInUp`}
      style={{ animationDuration: `${props.index * 0.4 + 1.25}s` }}
    >
      <div className={styles.flipCardInner}>
        <div className={styles.flipCardFront}>
          <div className={styles.flipCardFrontContent}>
            <div className="tw-px-9 tw-pt-[50px] tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-start">
              <h3 className="tw-text-[70px] tw-font-[600]">{props.frontTitle}</h3>
              <p className=" tw-text-center">{props.frontDesc}</p>
            </div>
          </div>
        </div>
        <div className={styles.flipCardBack}>
          <div className={styles.flipCardBackContent}>
            <div className="tw-h-full tw-px-9 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2">
              <h5 className="tw-text-[19px] tw-font-600]">{props.backTitle}</h5>
              <a href={props.path}>
                <button className={styles.startButton}>Оқуды бастау</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
